import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "gatsby";

import "../styles/centered-box.less";

import { Layout, Row, Col, Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { navigate } from "../utils";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

export default function EspaceRider() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      return navigate("/mon-espace-rider");
    }
  }, []);

  function handleSubmit({ email, password }) {
    setIsLoading(true);
    axios
      .post(process.env.GATSBY_API_URL + "/access-rider-space", {
        email,
        password,
      })
      .then(({ data: { accessToken } }) => {
        localStorage.setItem("accessToken", accessToken);
        navigate("/mon-espace-rider");
      })
      .catch(() => {
        message.error(
          "Erreur lors de l'authentification. Votre email ou votre mot de passe semble incorrect. Si vous pensez à une erreur, merci de prendre contact avec nous."
        );
        return navigate("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Espace Rider</h2>
            <Row>
              <Col
                lg={{ span: 10, offset: 7 }}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  className="login-form"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Merci de saisir votre numéro d'assuré",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Merci de saisir votre mot de passe",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="Mot de passe"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={isLoading}
                    >
                      S'identifier
                    </Button>
                    <br />
                    <br />
                    <Link to="/reset-rider-access">Mot de passe oublié ?</Link>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
